<script setup lang="ts">
import logoutSmall from "assets/graphics/icons/cb_logout_small.svg";
import { signOutUser } from "~/composables/useFirebase";
import { FacilityFirestore } from "~/types/facility";
import NavBarLink from "~/components/NavBarLink.vue";

const facilityStore = useFacilityStore();
const facilityStoreRefs = storeToRefs(facilityStore);
const facility: Ref<FacilityFirestore | null> = facilityStoreRefs.facility;

const handleLogout = () => {
  signOutUser()
    .then(() => {
      navigateTo("/login");
    })
    .catch((error) => {
      console.log(error);
    });
};
</script>
<template>
  <div class="top-bar-wrapper">
    <h1 class="facility-name-top">{{ facility?.title }}</h1>
    <div class="top-links">
      <NavBarLink
        :text="$t('navigation.main_news')"
        icon="info_circle"
        :is-chip="true"
        to="/news"
        class="top-link top-link-spacing"
      />
      <NavBarLink
        :text="$t('navigation.help')"
        icon="helpSmall"
        :is-chip="true"
        to="/manuals"
        class="top-link top-link-spacing"
      />
      <NavBarLink
        :text="$t('navigation.settings')"
        icon="settingsSmall"
        :is-chip="true"
        to="/settings"
        class="top-link top-link-spacing"
      />
      <NavBarLink
        :text="$t('navigation.overview')"
        icon="homeSmall"
        :is-chip="true"
        to="/"
        class="top-link top-link-spacing"
      />
      <a class="cb_nav-link d-flex logout-btn top-link-spacing" @click="handleLogout">
        <img :src="logoutSmall"
      /></a>
    </div>
  </div>
</template>
<style scoped>
.facility-name-top {
    font-size: 2rem;
    font-weight: 900;
}
.top-bar-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 4px;
}
.top-links {
  display: flex;
  flex-wrap: wrap;
}
.top-link {
  color: #000f28;
}
</style>
