<script setup>
import "~/assets/css/main.css";
import "~/assets/css/cb_custom.css";
import NavMenu from "~/components/Navigation/NavMenu.vue";
import TopHeaderBar from "~/components/Navigation/TopHeaderBar.vue";
import MobileHeader from "~/components/Navigation/MobileHeader";

// TODO: move somewhere else
useUserStore();
useFacilityStore();
useCareDayStore();
useMenuStore();


const isMenuOpen = ref(false);
const isOpenOnClick = ref(false);

const toggleMenu = () => {
  isOpenOnClick.value = !isOpenOnClick.value;
  isMenuOpen.value = isOpenOnClick.value;
};

const onMouseEnter = () => {
  if (isOpenOnClick.value) {
    return;
  }
  isMenuOpen.value = true;
};

const onMouseLeave = () => {
  if (isOpenOnClick.value) {
    return;
  }
  isMenuOpen.value = false;
};

const isMobileView = ref(window.innerWidth < 926);
const handleResize = () => {
  isMobileView.value = window.innerWidth < 926;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

</script>

<template>
  <div :class="[{'default-container': !isMobileView}]">
    <!-- <NavBar /> -->
    <NavMenu
      v-if="!isMobileView"
      :is-menu-open="isMenuOpen"
      @menu-click="toggleMenu"
      @on-mouse-enter="onMouseEnter"
      @on-mouse-leave="onMouseLeave"
    />
    <MobileHeader v-else />

    <div :class="['content-default']">
      <div class="container">
        <TopHeaderBar v-if="!isMobileView" />

        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
.content-container {
  min-width: 300px;
  max-width: 1540px;
  width: auto;
  margin: 20px 40px;
}
.default-container {
  display: flex;
  height: 100%;
}
.content-default {
  flex-grow: 1;
  padding: 0 20px;
}
</style>
